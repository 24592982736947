export default {
  name: 'auth',
  base: '',
  defaultEndpoints: false,
  endpoints: [
    {
      method: 'POST',
      endpoint: '/login',
      hasParams: false,
      hasBody: true,
      requiresAuth: false,
      alias: 'login'
    },
    {
      method: 'POST',
      endpoint: '/logout',
      hasParams: false,
      hasBody: false,
      requiresAuth: true,
      alias: 'logout'
    },
    {
      method: 'GET',
      endpoint: '/abilities',
      hasParams: false,
      hasBody: false,
      requiresAuth: true,
      alias: 'abilities'
    }
  ]
}
