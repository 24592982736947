const Page404 = () => import('@/views/errors/Page404')
const Page500 = () => import('@/views/errors/Page500')

export default [{
  path: '/error',
  redirect: '/error/404',
  name: 'Pages',
  component: {
    render (c) { return c('router-view') }
  },
  children: [
    {
      path: '404',
      name: 'Page404',
      component: Page404
    },
    {
      path: '500',
      name: 'Page500',
      component: Page500
    }
  ]
}]
