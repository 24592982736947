export default {
  name: 'strategies',

  endpoints: [
    {
      method: 'GET',
      endpoint: '/info',
      hasParams: false,
      alias: 'info'
    },
    {
      method: 'GET',
      endpoint: '/client/:id',
      hasParams: true,
      alias: 'client'
    },
    {
      method: 'GET',
      endpoint: '/:id/analyze',
      hasParams: true,
      alias: 'analyze'
    },
    {
      method: 'GET',
      endpoint: '/:id/pdf',
      hasParams: true,
      alias: 'pdf',
      options: {
        responseType: 'arraybuffer'
      }
    }
  ],
  services: [
    {
      name: 'debts',
      base: '/debts',
      defaultEndpoints: true
    },
    {
      name: 'inputs',
      base: '/inputs',
      defaultEndpoints: true
    }
  ]
}
