export default {
  name: 'accounts',

  endpoints: [
    {
      method: 'GET',
      endpoint: '/info',
      hasParams: false,
      alias: 'info'
    },
    {
      method: 'GET',
      endpoint: '/me',
      hasParams: false,
      hasBody: false,
      requiresAuth: true,
      alias: 'me'
    },
    {
      method: 'GET',
      endpoint: '/abilityGroups',
      hasParams: false,
      alias: 'abilityGroups'
    },
    {
      method: 'POST',
      endpoint: '/:accountId/roles/:roleId',
      hasParams: true,
      alias: 'addRole'
    },
    {
      method: 'DELETE',
      endpoint: '/:accountId/roles/:roleId',
      hasParams: true,
      alias: 'removeRole'
    }
  ],
  services: [
    {
      name: 'organizations',
      base: '/organizations',
      defaultEndpoints: true,
      alias: 'organizations'
    },
    {
      name: 'roles',
      base: '/roles',
      defaultEndpoints: true,
      alias: 'roles',
      endpoints: [
        {
          method: 'POST',
          endpoint: '/:roleId/abilities/:abilityId',
          hasParams: true,
          alias: 'addAbility'
        },
        {
          method: 'DELETE',
          endpoint: '/:roleId/abilities/:abilityId',
          hasParams: true,
          alias: 'removeAbility'
        }
      ]
    }
  ]
}
