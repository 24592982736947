export default {
  name: 'configurations',
  base: '',
  defaultEndpoints: true,
  endpoints: [
    {
      method: 'PUT',
      endpoint: '/bulk',
      hasParams: false,
      hasBody: true,
      requiresAuth: false,
      alias: 'bulkUpdate'
    }
  ],
  services: [
    {
      name: 'taxBrackets',
      base: '',
      defaultEndpoints: true
    }
  ]
}
