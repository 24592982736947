import Papi from '@papijs/papi'
import { baseURL } from '@/../.axiosrc'

import accounts from './services/accounts'
import assets from './services/assets'
import auth from './services/auth'
import clients from './services/clients'
import configurations from './services/configurations'
import contacts from './services/contacts'
import creditcards from './services/creditcards'
import email from './services/email'
import loans from './services/loans'
import mortgages from './services/mortgages'
import payments from './services/payments'
import policies from './services/policies'
import strategies from './services/strategies'
import vehicles from './services/vehicles'

const headers = []

if (localStorage.getItem('token')) {
  headers.push([
    'Authorization', 'bearer ' + localStorage.getItem('token')
  ])
}

const instance = Papi({
  base: `${baseURL}/v1`,
  headers,
  services: [
    accounts,
    assets,
    auth,
    clients,
    configurations,
    contacts,
    creditcards,
    email,
    loans,
    mortgages,
    payments,
    policies,
    strategies,
    vehicles
  ]
})
export default instance
