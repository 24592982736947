// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')
const Sidebar = () => import('@/views/sidebar')
// Views
const Dashboard = () => import('@/views/Dashboard')
const Profile = () => import('@/views/Profile')

export default [{
  path: '/',
  redirect: '/login',
  name: 'Home',
  component: DefaultContainer,
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: 'dashboard',
      name: 'Dashboard',
      components: {
        sidebar: Sidebar,
        default: Dashboard,
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'profile',
      name: 'Profile',
      components: {
        sidebar: Sidebar,
        default: Profile,
      },
      meta: {
        requiresAuth: true
      }
    }
  ]
}]
