import Vue from 'vue'
import store from '@/store'
import Router from 'vue-router'

// Sub Routes
import authentication from './routes/authentication'
import clients from './routes/clients'
import dashboard from './routes/dashboard'
import errors from './routes/errors'
import helpCenter from './routes/help-center'
import policies from './routes/policies'
import settings from './routes/settings'

Vue.use(Router)

let router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    ...authentication,
    ...dashboard,
    ...errors,
    ...helpCenter,
    ...clients,
    ...policies,
    ...settings
  ]
})

router.beforeResolve((to, from, next) => {
  const loggedIn = store.getters['global/loggedIn']

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!loggedIn) {
      next({
          path: '/login'
      })
    } else {
      next()
    }
  } else if(to.matched.some(record => record.meta.authRedirect)) {
    if (loggedIn) {
      next(to.meta.authRedirect)
    } else {
      next()
    }
  } else {
    next()
  }
})

// const loginConfirmed = to => {
//   const loggedIn = store.getters['global/loggedIn']
//   if (loggedIn) {
//     console.log('logged in')
//   } else {
//     console.log('no logged in')
//   }
//   return (loggedIn || to.name === 'Login')
// }

export default router
