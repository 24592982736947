import authentication from './authentication'
import clients from './clients'
import intake from './intake'
import notifications from './notifications'
import system from './system'
import types from './types'

const init = ({dispatch}) => {
  dispatch('getAccount')
  dispatch('getAbilities')
  // dispatch('getAssetTypes')
  dispatch('getCountries')
  dispatch('getProvinces')
  dispatch('getInsuranceCompanies')
}

export default {
  init,
  ...authentication,
  ...clients,
  ...intake,
  ...notifications,
  ...system,
  ...types
}
