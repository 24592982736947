import api from '@/api'

const getClient = ({commit, dispatch}, body) => {
  commit('SET_CURRENT_CLIENT', {
    id: null,
    primaryContact: {
      firstName: null,
      lastName: null,
      address: {
        street: null,
        city: null,
        state: null,
        province: {
          name: null
        },
        postalCode: null,
        zipcode: null,
        country: {
          name: null
        }
      },
      income: {
        monthly: 0.00,
        additionalMonthly: 0.00
      },
      existingPolicies: []
    },
    secondaryContact: {
      income: {
        monthly: 0.00,
        additionalMonthly: 0.00
      },
      existingPolicies: []
    },
    intakeRequests: [],
    assets: [],
    groupedAssets: [],
    creditCards: [],
    loans: [],
    mortgages: [],
    strategies: [],
    vehicles: [],
    inputs: {
      assets: [],
      creditCards: [],
      loans: [],
      mortgages: [],
      policies: [],
      vehicles: []
    },
    debts: {
      creditCards: [],
      loans: [],
      mortgages: [],
      vehicles: []
    }
  })

  // Get client info
  api.clients.get(body.id)
    .then((response) => {
      commit('SET_CURRENT_CLIENT', response.data)
    })
    .catch((err) => {
      dispatch('notification', {
        group: 'api',
        title: 'Fetch Failed',
        text: 'Error Fetching Client',
        type: 'err'
      })
    })

  dispatch('getClientAssets', body)
  dispatch('getClientGroupedAssets', body)
  dispatch('getClientCreditCards', body)
  dispatch('getClientLoans', body)
  dispatch('getClientMortgages', body)
  dispatch('getClientPolicies', body)
  dispatch('getClientStrategies', body)
  dispatch('getClientVehicles', body)
}

const getClientAssets = ({commit, dispatch}, body) => {
  // Get assets
  api.assets.client(body.id)
    .then((response) => {
      commit('SET_CLIENT_ASSETS', response.data)
      const inputAssets = response.data.map((asset) => {
        return {
          name: `${asset.name || 'Un-named Asset'}`,
          type: 'Asset',
          categoryId: asset.type.inputCategoryId,
          inputCategory: asset.type.inputCategory,
          assetId: asset.id,
          balanceAvailable: asset.balanceAvailable,
          monthlyAmountAvailable: asset.monthlyAmountAvailable,
          penalty: 0.0,
          taxes: 0.0
        }
      })
      commit('SET_CLIENT_INPUT_ASSETS', inputAssets)
    })
    .catch(() => {
      dispatch('notification', {
        group: 'api',
        title: 'Fetch Failed',
        text: 'Error Fetching Client Assets',
        type: 'err'
      })
    })
}

const getClientGroupedAssets = ({commit, dispatch}, body) => {
  // Get assets
  api.assets.clientGrouped(body.id)
    .then((response) => {
      commit('SET_CLIENT_GROUPED_ASSETS', response.data)
    })
    .catch(() => {
      dispatch('notification', {
        group: 'api',
        title: 'Fetch Failed',
        text: 'Error Fetching Client Grouped Assets',
        type: 'err'
      })
    })
}

const getClientCreditCards = ({commit, dispatch}, body) => {
  // Get Credit Cards
  api.creditcards.client(body.id)
    .then((response) => {
      commit('SET_CLIENT_CREDITCARDS', response.data)

      const inputCreditCards = response.data.map((creditCard) => {
        return {
          name: `${creditCard.name}`,
          type: 'Credit Card',
          categoryId: creditCard.type.inputCategoryId,
          inputCategory: creditCard.type.inputCategory,
          creditCardId: creditCard.id,
          balanceAvailable: creditCard.balanceAvailable,
          monthlyAmountAvailable: creditCard.monthlyAmountAvailable,
          penalty: 0.0,
          taxes: 0.0
        }
      })
      commit('SET_CLIENT_INPUT_CREDITCARDS', inputCreditCards)
    })
    .catch(() => {
      dispatch('notification', {
        group: 'api',
        title: 'Fetch Failed',
        text: 'Error Fetching Client Credit Cards',
        type: 'err'
      })
    })
}

const getClientLoans = ({commit, dispatch}, body) => {
  // Get Loans
  api.loans.client(body.id)
    .then((response) => {
      commit('SET_CLIENT_LOANS', response.data)

      const inputLoans = response.data.map((loan) => {
        return {
          name: `${loan.name}`,
          type: 'Loan',
          categoryId: loan.inputCategoryId,
          inputCategory: loan.inputCategory,
          loanId: loan.id,
          balanceAvailable: loan.balanceAvailable,
          monthlyAmountAvailable: loan.monthlyAmountAvailable,
          penalty: 0.0,
          taxes: 0.0
        }
      })
      commit('SET_CLIENT_INPUT_LOANS', inputLoans)
    })
    .catch(() => {
      dispatch('notification', {
        group: 'api',
        title: 'Fetch Failed',
        text: 'Error Fetching Client Loans',
        type: 'err'
      })
    })
}

const getClientMortgages = ({commit, dispatch}, body) => {
  // Get Mortgages
  api.mortgages.client(body.id)
    .then((response) => {
      commit('SET_CLIENT_MORTGAGES', response.data)

      const inputMortgages = response.data.map((mortgage) => {
        return {
          name: `${mortgage.name}`,
          type: 'Mortgage',
          categoryId: mortgage.type.inputCategoryId,
          inputCategory: mortgage.type.inputCategory,
          mortgageId: mortgage.id,
          balanceAvailable: mortgage.balanceAvailable,
          monthlyAmountAvailable: mortgage.monthlyAmountAvailable,
          penalty: 0.0,
          taxes: 0.0
        }
      })
      commit('SET_CLIENT_INPUT_MORTGAGES', inputMortgages)
    })
    .catch(() => {
      dispatch('notification', {
        group: 'api',
        title: 'Fetch Failed',
        text: 'Error Fetching Client Mortgages',
        type: 'err'
      })
    })
}

const getClientPolicies = ({commit, dispatch}, body) => {
  // Get Policies
  api.policies.client(body.id)
    .then((response) => {
      // commit('SET_CLIENT_LOANS', response.data)

      const inputPolicies = response.data.filter(o => o.typeId > 0 && o.existing && !o.sourcePolicyId).map((policy) => {
        return {
          name: `${policy.name || 'Un-named Policy'}`,
          type: 'Policy',
          categoryId: policy.type.inputCategoryId,
          inputCategory: policy.type.inputCategory,
          policyId: policy.id,
          balanceAvailable: policy.balanceAvailable,
          monthlyAmountAvailable: policy.monthlyAmountAvailable,
          penalty: 0.0,
          taxes: 0.0
        }
      })

      commit('SET_CLIENT_INPUT_POLICIES', inputPolicies)
    })
    .catch((err) => {
      console.log(err)
      dispatch('notification', {
        group: 'api',
        title: 'Fetch Failed',
        text: 'Error Fetching Client Policies',
        type: 'err'
      })
    })
}

const getClientVehicles = ({commit, dispatch}, body) => {
  // Get Vehicles
  api.vehicles.client(body.id)
    .then((response) => {
      commit('SET_CLIENT_VEHICLES', response.data)

      const inputVehicles = response.data.map((vehicle) => {
        return {
          name: `${vehicle.name}`,
          type: 'Vehicle',
          categoryId: vehicle.inputCategoryId,
          inputCategory: vehicle.inputCategory,
          vehicleId: vehicle.id,
          balanceAvailable: vehicle.balanceAvailable,
          monthlyAmountAvailable: vehicle.monthlyAmountAvailable,
          penalty: 0.0,
          taxes: 0.0
        }
      })
      commit('SET_CLIENT_INPUT_VEHICLES', inputVehicles)
    })
    .catch(() => {
      dispatch('notification', {
        group: 'api',
        title: 'Fetch Failed',
        text: 'Error Fetching Client Vehicles',
        type: 'err'
      })
    })
}

const getClientStrategies = ({commit, dispatch}, body) => {
  api.strategies.client(body.id)
    .then((response) => {
      commit('SET_CLIENT_STRATEGIES', response.data)
    })
    .catch(() => {
      dispatch('notification', {
        group: 'api',
        title: 'Fetch Failed',
        text: 'Error Fetching Client Strategies',
        type: 'err'
      })
    })
}

const clearStrategy = ({commit}) => {
  commit('CLEAR_CURRENT_STRATEGY')
}

const getStrategy = ({commit, dispatch}, body) => {
  api.strategies.get(body.id)
    .then((response) => {
      commit('SET_CURRENT_STRATEGY', response.data)
    })
    .catch(() => {
      dispatch('notification', {
        group: 'api',
        title: 'Fetch Failed',
        text: 'Error Fetching Strategy',
        type: 'err'
      })
    })
}

const saveStrategy = ({commit, dispatch}, body) => {
  api.strategies.update(body.id, body)
    .then((response) => {
      commit('SET_CURRENT_STRATEGY', response.data)
      dispatch('notification', {
        group: 'api',
        title: 'Success',
        text: 'Strategy Saved',
        type: 'success'
      })
    })
    .catch(() => {
      dispatch('notification', {
        group: 'api',
        title: 'Fetch Failed',
        text: 'Error Fetching Strategy',
        type: 'err'
      })
    })
}

const getAnalysis = ({commit, dispatch}, body) => {
  api.strategies.analyze(body.id)
    .then((response) => {
      commit('SET_CURRENT_ANALYSIS', response.data)
    })
    .catch(() => {
      dispatch('notification', {
        group: 'api',
        title: 'Fetch Failed',
        text: 'Error Fetching Analysis',
        type: 'err'
      })
    })
}

const saveLastSearch = ({commit}, body) => {
  commit('SET_LAST_SEARCH', body)
}

export default {
  clearStrategy,
  getClient,
  getClientAssets,
  getClientGroupedAssets,
  getClientCreditCards,
  getClientLoans,
  getClientMortgages,
  getClientPolicies,
  getClientStrategies,
  getClientVehicles,
  getStrategy,
  saveStrategy,
  getAnalysis,
  saveLastSearch
}
