const loggedIn = state => state.loggedIn

const account = (state) => state.account

const notifications = (state) => state.notifications

const assetTypes = (state) => state.assetTypes

const countries = (state) => state.countries

const currentClient = (state) => state.currentClient

const currentStrategy = (state) => state.currentStrategy

const currentAnalysis = (state) => state.currentAnalysis

const insuranceCompanies = (state) => state.insuranceCompanies

const lastSearch = (state) => state.lastSearch

const roles = (state) => state.roles

const organizations = (state) => state.organizations

const provinces = (state) => state.provinces

const appVersion = (state) => state.packageVersion

export default {
  appVersion,
  loggedIn,
  account,
  assetTypes,
  countries,
  insuranceCompanies,
  notifications,
  currentClient,
  currentStrategy,
  currentAnalysis,
  lastSearch,
  roles,
  organizations,
  provinces
}
