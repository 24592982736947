export default {
  name: 'clients',
  defaultEndpoints: true,
  endpoints: [
    {
      method: 'GET',
      endpoint: '/info',
      hasParams: false,
      alias: 'info'
    },
    {
      method: 'POST',
      endpoint: '/findByEmail',
      hasParams: false,
      hasBody: true,
      alias: 'findByEmail'
    },
    {
      method: 'POST',
      endpoint: '/search',
      hasParams: false,
      hasBody: true,
      alias: 'search'
    }
  ],
  services: [
    {
      name: 'intakeRequests',
      base: '/intakeRequests',
      defaultEndpoints: true,
      endpoints: [
        {
          method: 'GET',
          endpoint: '/active',
          hasParams: false,
          hasBody: false,
          alias: 'active'
        },
        {
          method: 'GET',
          endpoint: '/recent',
          hasParams: false,
          hasBody: false,
          alias: 'recent'
        },
        {
          method: 'PUT',
          endpoint: '/:id/request',
          hasParams: true,
          hasBody: false,
          alias: 'request'
        },
        {
          method: 'PUT',
          endpoint: '/:id/complete',
          hasParams: true,
          hasBody: false,
          alias: 'complete'
        },
        {
          method: 'PUT',
          endpoint: '/:id/accept',
          hasParams: true,
          hasBody: false,
          alias: 'accept'
        },
        {
          method: 'PUT',
          endpoint: '/:id/reopen',
          hasParams: true,
          hasBody: false,
          alias: 'reopen'
        }
      ]
    },
    {
      name: 'intake',
      base: '/intake',
      defaultEndpoints: false,
      endpoints: [
        {
          method: 'GET',
          endpoint: '/:token',
          hasParams: true,
          alias: 'get'
        },
        {
          method: 'PUT',
          endpoint: '/:token',
          hasParams: true,
          hasBody: true,
          alias: 'save'
        },
        {
          method: 'PUT',
          endpoint: '/:token/completed',
          hasParams: true,
          hasBody: false,
          alias: 'complete'
        },
        {
          method: 'PUT',
          endpoint: '/:token/clients/:id',
          hasParams: true,
          hasBody: true,
          alias: 'updateClient'
        },
        {
          method: 'GET',
          endpoint: '/:token/policies',
          hasParams: true,
          hasBody: false,
          alias: 'getPolicies'
        },
        {
          method: 'GET',
          endpoint: '/:token/policies/:id',
          hasParams: true,
          hasBody: false,
          alias: 'getPolicy'
        },
        {
          method: 'POST',
          endpoint: '/:token/policies',
          hasParams: true,
          hasBody: true,
          alias: 'createPolicy'
        },
        {
          method: 'PUT',
          endpoint: '/:token/policies/:id',
          hasParams: true,
          hasBody: true,
          alias: 'updatePolicy'
        },
        {
          method: 'DELETE',
          endpoint: '/:token/policies/:id',
          hasParams: true,
          hasBody: false,
          alias: 'deletePolicy'
        },
        {
          method: 'GET',
          endpoint: '/:token/assets',
          hasParams: true,
          hasBody: false,
          alias: 'getAssets'
        },
        {
          method: 'GET',
          endpoint: '/:token/assets/:id',
          hasParams: true,
          hasBody: false,
          alias: 'getAsset'
        },
        {
          method: 'POST',
          endpoint: '/:token/assets',
          hasParams: true,
          hasBody: true,
          alias: 'createAsset'
        },
        {
          method: 'PUT',
          endpoint: '/:token/assets/:id',
          hasParams: true,
          hasBody: true,
          alias: 'updateAsset'
        },
        {
          method: 'DELETE',
          endpoint: '/:token/assets/:id',
          hasParams: true,
          hasBody: false,
          alias: 'deleteAsset'
        },
        {
          method: 'GET',
          endpoint: '/:token/contacts',
          hasParams: true,
          hasBody: false,
          alias: 'getContacts'
        },
        {
          method: 'GET',
          endpoint: '/:token/contacts/:id',
          hasParams: true,
          hasBody: false,
          alias: 'getContact'
        },
        {
          method: 'POST',
          endpoint: '/:token/contacts',
          hasParams: true,
          hasBody: true,
          alias: 'createContact'
        },
        {
          method: 'PUT',
          endpoint: '/:token/contacts/:id',
          hasParams: true,
          hasBody: true,
          alias: 'updateContact'
        },
        {
          method: 'DELETE',
          endpoint: '/:token/contacts/:id',
          hasParams: true,
          hasBody: false,
          alias: 'deleteContact'
        },
        {
          method: 'GET',
          endpoint: '/:token/creditCards',
          hasParams: true,
          hasBody: false,
          alias: 'getCreditCards'
        },
        {
          method: 'GET',
          endpoint: '/:token/creditCards/:id',
          hasParams: true,
          hasBody: false,
          alias: 'getCreditCard'
        },
        {
          method: 'POST',
          endpoint: '/:token/creditCards',
          hasParams: true,
          hasBody: true,
          alias: 'createCreditCard'
        },
        {
          method: 'PUT',
          endpoint: '/:token/creditCards/:id',
          hasParams: true,
          hasBody: true,
          alias: 'updateCreditCard'
        },
        {
          method: 'DELETE',
          endpoint: '/:token/creditCards/:id',
          hasParams: true,
          hasBody: false,
          alias: 'deleteCreditCard'
        },
        {
          method: 'GET',
          endpoint: '/:token/loans',
          hasParams: true,
          hasBody: false,
          alias: 'getLoans'
        },
        {
          method: 'GET',
          endpoint: '/:token/loans/:id',
          hasParams: true,
          hasBody: false,
          alias: 'getLoan'
        },
        {
          method: 'POST',
          endpoint: '/:token/loans',
          hasParams: true,
          hasBody: true,
          alias: 'createLoan'
        },
        {
          method: 'PUT',
          endpoint: '/:token/loans/:id',
          hasParams: true,
          hasBody: true,
          alias: 'updateLoan'
        },
        {
          method: 'DELETE',
          endpoint: '/:token/loans/:id',
          hasParams: true,
          hasBody: false,
          alias: 'deleteLoan'
        },
        {
          method: 'GET',
          endpoint: '/:token/mortgages',
          hasParams: true,
          hasBody: false,
          alias: 'getMortgages'
        },
        {
          method: 'GET',
          endpoint: '/:token/mortgages/:id',
          hasParams: true,
          hasBody: false,
          alias: 'getMortgage'
        },
        {
          method: 'POST',
          endpoint: '/:token/mortgages',
          hasParams: true,
          hasBody: true,
          alias: 'createMortgage'
        },
        {
          method: 'PUT',
          endpoint: '/:token/mortgages/:id',
          hasParams: true,
          hasBody: true,
          alias: 'updateMortgage'
        },
        {
          method: 'DELETE',
          endpoint: '/:token/mortgages/:id',
          hasParams: true,
          hasBody: false,
          alias: 'deleteMortgage'
        },
        {
          method: 'GET',
          endpoint: '/:token/vehicles',
          hasParams: true,
          hasBody: false,
          alias: 'getVehicles'
        },
        {
          method: 'GET',
          endpoint: '/:token/vehicles/:id',
          hasParams: true,
          hasBody: false,
          alias: 'getVehicle'
        },
        {
          method: 'POST',
          endpoint: '/:token/vehicles',
          hasParams: true,
          hasBody: true,
          alias: 'createVehicle'
        },
        {
          method: 'PUT',
          endpoint: '/:token/vehicles/:id',
          hasParams: true,
          hasBody: true,
          alias: 'updateVehicle'
        },
        {
          method: 'DELETE',
          endpoint: '/:token/vehicles/:id',
          hasParams: true,
          hasBody: false,
          alias: 'deleteVehicle'
        }
      ]
    }
  ]
}
