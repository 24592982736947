// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')

// Sidebars
const Sidebar = () => import('@/views/clients/sidebar')
const ClientSidebar = () => import('@/views/clients/sidebar/client')

// Clients
const Clients = () => import('@/views/clients/Clients')
const Client = () => import('@/views/clients/Client')

// Strategies
const Strategies = () => import('@/views/clients/strategies/Strategies')
const Inputs = () => import('@/views/clients/strategies/strategy/Inputs')
const Debts = () => import('@/views/clients/strategies/strategy/Debts')
const Design = () => import('@/views/clients/strategies/strategy/Design')
const Strategy = () => import('@/views/clients/strategies/strategy/Strategy')
const Summary = () => import('@/views/clients/strategies/strategy/Summary')

// Intake
const Intake = () => import('@/views/intake/Intake')
const Intake404 = () => import('@/views/intake/404')

export default [{
  path: '/clients',
  redirect: '/clients/search',
  name: 'Clients',
  component: {
    render (c) { return c('router-view') }
  },
  children: [
    {
      path: 'search',
      components: {
        sidebar: Sidebar,
        default: DefaultContainer
      },
      children: [
        {
          path: ':query?',
          components: {
            sidebar: Sidebar,
            default: Clients
          }
        }
      ]
    },
    {
      path: ':id',
      components: {
        sidebar: ClientSidebar,
        default: DefaultContainer
      },
      children: [
        {
          path: 'info',
          name: 'Client',
          components: {
            sidebar: ClientSidebar,
            default: Client
          }
        },
        {
          path: 'strategies',
          name: 'Strategies',
          components: {
            sidebar: ClientSidebar,
            default: Strategies
          },
        },
        {
          path: 'strategies/:strategyId',
          redirect: 'strategies/:strategyId/input'
        },
        {
          path: 'strategies/:strategyId/input',
          name: 'Inputs',
          components: {
            sidebar: ClientSidebar,
            default: Inputs
          },
        },
        {
          path: 'strategies/:strategyId/sequence',
          name: 'Debt Sequence',
          components: {
            sidebar: ClientSidebar,
            default: Debts
          }
        },
        {
          path: 'strategies/:strategyId/design',
          name: 'Policy Design',
          components: {
            sidebar: ClientSidebar,
            default: Design
          }
        },
        {
          path: 'strategies/:strategyId/strategy',
          name: 'Strategy',
          components: {
            sidebar: ClientSidebar,
            default: Strategy
          }
        },
        {
          path: 'strategies/:strategyId/summary',
          name: 'Summary',
          components: {
            sidebar: ClientSidebar,
            default: Summary
          }
        }
      ]
    }
  ]
},
{
  path: '/clients/:token/intake',
  name: 'Intake',
  component: Intake
},
{
  path: '/clients/:token/intake404',
  name: 'Intake404',
  component: Intake404
}]
