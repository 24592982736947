// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
// import 'core-js/es6/promise'
// import 'core-js/es6/string'
// import 'core-js/es7/array'
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInfinity } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vuelidate from 'vuelidate'
import Snotify, { SnotifyPosition } from 'vue-snotify'
import App from './App'
import router from './router'
import api from './api'
import axios from './axios'

import store from './store'

// todo
// cssVars()
library.add(faInfinity)

Vue.prototype.$axios = axios

Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.component('font-awesome-icon', FontAwesomeIcon)

const options = {
  toast: {
    position: SnotifyPosition.rightTop,
    timeout: 5000
  }
}

Vue.use(Snotify, options)

const EventBus = new Vue()

Object.defineProperties(Vue.prototype, {
  $bus: {
    get: function () {
      return EventBus
    }
  }
})

Vue.prototype.$api = api

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App
  }
})
