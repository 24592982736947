const PrivacyPolicy = () => import('@/views/PrivacyPolicy')
const TermsAndConditions = () => import('@/views/TermsAndConditions')

export default [
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: PrivacyPolicy
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms and Conditions',
    component: TermsAndConditions
  }
]
