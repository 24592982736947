export default {
  name: 'loans',

  endpoints: [
    {
      method: 'GET',
      endpoint: '/info',
      hasParams: false,
      alias: 'info'
    },
    {
      method: 'GET',
      endpoint: '/client/:id',
      hasParams: true,
      alias: 'client'
    }
  ]
}
