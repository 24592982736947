import axios from '@/axios'

const getAssetTypes = ({commit, dispatch}, countryId) => {
  axios.get(`/v1/assets/types?countryId=${countryId}`).then((response) => {
    commit('SET_ASSET_TYPES', response.data)
  }).catch((err) => {
    dispatch('notification', {
      group: 'api',
      title: 'Fetch Failed',
      text: 'Error Fetching Asset Types',
      type: 'err'
    })
  })
}

export default {
  getAssetTypes
}
