const SET_LOGGED_IN = (state, status) => {
  state.loggedIn = status
}

const SET_ACCOUNT = (state, account) => {
  state.account = account
}

const SET_ASSET_TYPES = (state, assetTypes) => {
  state.assetTypes = assetTypes
}

const SET_CURRENT_CLIENT = (state, client) => {
  state.currentClient = Object.assign(state.currentClient, client)
}

const SET_CLIENT_ASSETS = (state, assets) => {
  state.currentClient.assets = assets
}

const SET_CLIENT_INPUT_ASSETS = (state, assets) => {
  state.currentClient.inputs.assets = assets
}

const SET_CLIENT_GROUPED_ASSETS = (state, groupedAssets) => {
  state.currentClient.groupedAssets = groupedAssets
}

const SET_CLIENT_CREDITCARDS = (state, creditCards) => {
  state.currentClient.creditCards = creditCards
}

const SET_CLIENT_INPUT_CREDITCARDS = (state, creditCards) => {
  state.currentClient.inputs.creditCards = creditCards
}

const SET_CLIENT_LOANS = (state, loans) => {
  state.currentClient.loans = loans
}

const SET_CLIENT_INPUT_LOANS = (state, loans) => {
  state.currentClient.inputs.loans = loans
}

const SET_CLIENT_INPUT_POLICIES = (state, policies) => {
  state.currentClient.inputs.policies = policies
}

const SET_CLIENT_MORTGAGES = (state, mortgages) => {
  state.currentClient.mortgages = mortgages
}

const SET_CLIENT_INPUT_MORTGAGES = (state, mortgages) => {
  state.currentClient.inputs.mortgages = mortgages
}

const SET_CLIENT_VEHICLES = (state, vehicles) => {
  state.currentClient.vehicles = vehicles
}

const SET_CLIENT_INPUT_VEHICLES = (state, vehicles) => {
  state.currentClient.inputs.vehicles = vehicles
}

const SET_CLIENT_STRATEGIES = (state, strategies) => {
  state.currentClient.strategies = strategies
}

const CLEAR_CURRENT_STRATEGY = (state) => {
  state.currentStrategy = {
    name: null,
    taxRate: null,
    capitalGainsTaxRate: null
  }
}

const SET_CURRENT_STRATEGY = (state, strategy) => {
  state.currentStrategy = strategy
}

const SET_CURRENT_ANALYSIS = (state, analysis) => {
  state.currentAnalysis = analysis
}

const SET_COUNTRIES = (state, countries) => {
  state.countries = countries
}

const SET_INSURANCE_COMPANIES = (state, insuranceCompanies) => {
  state.insuranceCompanies = insuranceCompanies
}

const ADD_NOTIFICATION = (state, notification) => {
  state.notifications.push(notification)
}

const REMOVE_NOTIFICATION = (state, index) => {
  state.notifications.splice(index, 1)
}

const CLEAR_NOTIFICATIONS = (state) => {
  state.notifications = []
}

const SET_LAST_SEARCH = (state, string) => {
  state.lastSearch = string
}

const SET_ROLES = (state, roles) => {
  state.roles = roles
}

const SET_PROVINCES = (state, provinces) => {
  state.provinces = provinces
}

export default {
  SET_LOGGED_IN,
  SET_ACCOUNT,
  SET_ASSET_TYPES,
  SET_COUNTRIES,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
  CLEAR_CURRENT_STRATEGY,
  SET_CURRENT_CLIENT,
  SET_CLIENT_ASSETS,
  SET_CLIENT_INPUT_ASSETS,
  SET_CLIENT_GROUPED_ASSETS,
  SET_CLIENT_CREDITCARDS,
  SET_CLIENT_INPUT_CREDITCARDS,
  SET_CLIENT_LOANS,
  SET_CLIENT_INPUT_LOANS,
  SET_CLIENT_MORTGAGES,
  SET_CLIENT_INPUT_MORTGAGES,
  SET_CLIENT_INPUT_POLICIES,
  SET_CLIENT_STRATEGIES,
  SET_CLIENT_VEHICLES,
  SET_CLIENT_INPUT_VEHICLES,
  SET_CURRENT_STRATEGY,
  SET_CURRENT_ANALYSIS,
  SET_INSURANCE_COMPANIES,
  SET_LAST_SEARCH,
  SET_ROLES,
  SET_PROVINCES
}
