import ability from '@/store/ability'
import api from '@/api'
import router from '@/router'
import axios from '@/axios'

const getAbilities = ({commit, dispatch}) => {
  if (localStorage.getItem('token')) {
    api.auth.abilities()
      .then(({ data }) => {
        ability.update(data)
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          ability.update([])
        }
      })
  } else {
    ability.update([])
  }
}

const getAccount = ({commit, dispatch}) => {
  if (localStorage.getItem('token')) {
    api.accounts.me()
      .then(({ data }) => {
        commit('SET_ACCOUNT', data)
        commit('SET_LOGGED_IN', true)
        dispatch('getAssetTypes', data.organization.countryId)
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          commit('CLEAR_NOTIFICATIONS')
          dispatch('notification', {
            group: 'auth',
            title: 'Session Expired',
            text: 'Your session has expired',
            type: 'warn'
          })
          commit('SET_LOGGED_IN', false)
          router.push({name: 'Login'})
          localStorage.removeItem('token')
        }
      })
  } else {
    commit('SET_ACCOUNT', null)
  }
}

const login = ({commit, dispatch}, body) => {
  if (!body || !body.username || !body.password) {
    return false
  }
  commit('CLEAR_NOTIFICATIONS')

  api.auth.login(body)
    .then(({data}) => {
      commit('SET_LOGGED_IN', true)
      localStorage.setItem('token', data.token)
      api.updateHeader(['Authorization', 'Bearer ' + data.token])
      axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`
      dispatch('getAccount')
      dispatch('getAbilities')
      router.push({name: 'Dashboard'})
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch('notification', {
          group: 'auth',
          title: 'Authentication Failed',
          text: 'Invalid Username/Password',
          type: 'err'
        })
      }
    })
}

const logout = ({commit, dispatch}) => {
  if (localStorage.getItem('token')) {
    api.auth.logout()
      .then(({status}) => {
        if (status === 200) {
          commit('SET_LOGGED_IN', false)
          localStorage.removeItem('token')
          axios.defaults.headers.common['Authorization'] = null
          ability.update([])
          dispatch('getAccount')
          dispatch('getAbilities')
          router.push({name: 'Login'})
        }
      })
  }
}

export default {
  getAbilities,
  getAccount,
  login,
  logout
}
