// Views
const Login = () => import('@/views/Login')

export default [{
  path: '/login',
  name: 'Login',
  component: Login,
  meta: {
    requiresAuth: false,
    authRedirect: '/dashboard'
  }
}]
