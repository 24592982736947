export default {
  name: 'payments',
  endpoints: [
    {
      method: 'GET',
      endpoint: '/info',
      hasParams: false,
      alias: 'info'
    }
  ],
  services: [
    {
      name: 'charges',
      base: '/charges',
      defaultEndpoints: true,
      endpoints: [
        {
          method: 'GET',
          endpoint: '/invoice/:invoiceId',
          hasParams: true,
          alias: 'getWithInvoice'
        }
      ]
    },
    {
      name: 'invoices',
      base: '/invoices',
      defaultEndpoints: true,
      endpoints: [
        {
          method: 'GET',
          endpoint: '/organization/:organizationId',
          hasParams: true,
          alias: 'getWithOrganization'
        },
        {
          method: 'POST',
          endpoint: '/pay/:id',
          hasParams: true,
          hasBody: true,
          alias: 'pay'
        }
      ]
    },
    {
      name: 'profiles',
      base: '/profiles',
      defaultEndpoints: true,
      endpoints: [
        {
          method: 'GET',
          endpoint: '/organization/:organizationId',
          hasParams: true,
          alias: 'getWithOrganization'
        }
      ]
    },
    {
      name: 'subscriptions',
      base: '/subscriptions',
      defaultEndpoints: false,
      endpoints: [
        {
          method: 'GET',
          endpoint: '/:organizationId',
          hasParams: true,
          alias: 'get'
        },
        {
          method: 'POST',
          endpoint: '/:organizationId',
          hasParams: true,
          hasBody: true,
          alias: 'upsert'
        },
        {
          method: 'POST',
          endpoint: '/generateInvoice/:organizationId',
          hasParams: true,
          hasBody: true,
          alias: 'generateInvoice'
        }
      ]
    },
    {
      name: 'transactions',
      base: '/transactions',
      defaultEndpoints: true,
      endpoints: [
        {
          method: 'GET',
          endpoint: '/invoice/:invoiceId',
          hasParams: true,
          alias: 'getWithInvoice'
        }
      ]
    },
  ]
}
