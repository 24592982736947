import actions from './actions/index'
import getters from './getters'
import mutations from './mutations'

let loggedIn = false

if (localStorage.getItem('token')) {
  loggedIn = true
}

/**
 * The initial state
 *
 * @constant {object}
 */
const state = {
  packageVersion: process.env.PACKAGE_VERSION || '0',
  loggedIn: loggedIn,
  account: null,
  countries: [],
  provinces: [],
  insuranceCompanies: [],
  notifications: [],
  assetTypes: [],
  lastSearch: null,
  currentClient: {
    id: null,
    primaryContact: {
      firstName: null,
      lastName: null,
      address: {
        street: null,
        city: null,
        state: null,
        province: {
          name: null
        },
        postalCode: null,
        zipcode: null,
        country: {
          name: null
        }
      },
      income: {
        monthly: 0.00,
        additionalMonthly: 0.00
      },
      existingPolicies: []
    },
    secondaryContact: {
      income: {
        monthly: 0.00,
        additionalMonthly: 0.00
      },
      existingPolicies: []
    },
    intakeRequests: [],
    assets: [],
    groupedAssets: [],
    creditCards: [],
    loans: [],
    mortgages: [],
    strategies: [],
    vehicles: [],
    inputs: {
      assets: [],
      creditCards: [],
      loans: [],
      mortgages: [],
      policies: [],
      vehicles: []
    },
    debts: {
      creditCards: [],
      loans: [],
      mortgages: [],
      vehicles: []
    }
  },
  currentStrategy: {
    name: null,
    taxRate: null,
    capitalGainsTaxRate: null
  },
  currentAnalysis: {},
  roles: []
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
