const notification = ({commit}, notification) => {
  commit('ADD_NOTIFICATION', notification)
}

const ackNotification = ({commit}) => {
  commit('REMOVE_NOTIFICATION')
}

export default {
  notification,
  ackNotification
}
