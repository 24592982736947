import axios from 'axios'
import { baseURL } from '@/../.axiosrc'

const instance = axios.create({
  baseURL
})

if (localStorage.getItem('token')) {
  const token = localStorage.getItem('token')
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export default instance
