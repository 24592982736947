export default {
  name: 'email',

  endpoints: [
    {
      method: 'GET',
      endpoint: '/info',
      hasParams: false,
      alias: 'info'
    }
  ],
  services: [
    {
      name: 'templates',
      endpoints: [
        {
          method: 'GET',
          endpoint: '/organization/:organizationId',
          hasParams: true,
          alias: 'getWithOrganization'
        }
      ]
    },
    {
      name: 'organizationTemplates'
    }
  ]
}
