import api from '@/api'
import axios from '@/axios'

const getCountries = ({commit, dispatch}) => {
  axios.get(`/v1/contacts/countries`).then((response) => {
    commit('SET_COUNTRIES', response.data)
  }).catch((err) => {
    dispatch('notification', {
      group: 'api',
      title: 'Fetch Failed',
      text: 'Error Fetching Countries',
      type: 'err'
    })
  })
}

const getRoles = ({commit, dispatch}) => {
  api.accounts.roles.get()
    .then((response) => {
      commit('SET_ROLES', response.data)
    })
    .catch(() => {
      dispatch('notification', {
        group: 'api',
        title: 'Fetch Failed',
        text: 'Error Fetching Roles',
        type: 'err'
      })
    })
}

const getProvinces = ({commit, dispatch}) => {
  axios.get(`/v1/contacts/provinces`).then((response) => {
    commit('SET_PROVINCES', response.data)
  }).catch((err) => {
    dispatch('notification', {
      group: 'api',
      title: 'Fetch Failed',
      text: 'Error Fetching Provinces',
      type: 'err'
    })
  })
}

const getInsuranceCompanies = ({commit, dispatch}) => {
  axios.get(`/v1/policies/insuranceCompanies`).then((response) => {
    commit('SET_INSURANCE_COMPANIES', response.data)
  }).catch((err) => {
    dispatch('notification', {
      group: 'api',
      title: 'Fetch Failed',
      text: 'Error Fetching Insurance Companies',
      type: 'err'
    })
  })
}

export default {
  getCountries,
  getRoles,
  getProvinces,
  getInsuranceCompanies
}
