import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'vue-moment'
import { abilitiesPlugin } from '@casl/vue'

import ability from './ability'
import global from './global'
import Events from './events'

Vue.use(Vuex)
Vue.use(moment)
Vue.use(abilitiesPlugin, ability)

export default new Vuex.Store({
  modules: {
    $_events: Events,
    global
  }
})
