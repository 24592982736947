export default {
  name: 'contacts',
  defaultEndpoints: true,
  endpoints: [
    {
      method: 'GET',
      endpoint: '/info',
      hasParams: false,
      alias: 'info'
    },
    {
      method: 'GET',
      endpoint: '/client/:clientId',
      hasParams: true,
      alias: 'clientContacts'
    }
  ],
  services: [
    {
      name: 'types',
      base: '/types',
      defaultEndpoints: true
    }
  ]
}
