// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')
const Sidebar = () => import('@/views/sidebar/help-center')
// Views
const Help = () => import('@/views/Help')
const Tutorials = () => import('@/views/Tutorials')
const VersionHistory = () => import('@/views/VersionHistory')

export default [{
  path: '/help-center',
  redirect: '/help-center/help',
  name: 'Help Center',
  component: DefaultContainer,
  children: [
    {
      path: 'help',
      name: 'Help',
      components: {
        sidebar: Sidebar,
        default: Help,
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'tutorials',
      name: 'Tutorials',
      components: {
        sidebar: Sidebar,
        default: Tutorials,
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'versions',
      name: 'VersionHistory',
      components: {
        sidebar: Sidebar,
        default: VersionHistory,
      },
      meta: {
        requiresAuth: true
      }
    }
  ]
}]
