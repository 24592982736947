// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')
const Sidebar = () => import('@/views/settings/sidebar')

// System
const Accounts = () => import('@/views/accounts/Accounts')
const Account = () => import('@/views/accounts/Account')
const ApplicationSettings = () => import('@/views/settings/general/Application')

// Billing
const Billing = () => import('@/views/settings/subscription/Billing')

// Templates
const EmailTemplates = () => import('@/views/settings/templates/Email')

// Integrations
const CardConnect = () => import('@/views/settings/integrations/CardConnect')
const Mailgun = () => import('@/views/settings/integrations/Mailgun')

// Organizations
const Organizations = () => import('@/views/settings/Organizations')
const Organization = () => import('@/views/settings/Organization')

// Roles
const Roles = () => import('@/views/settings/system/Roles')
// const Role = () => import('@/views/settings/Role')

export default [{
  path: '/settings',
  redirect: '/settings/accounts',
  name: 'Settings',
  components: {
    sidebar: Sidebar,
    default: DefaultContainer
  },
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: 'accounts',
      redirect: '/settings/accounts/list',
      components: {
        sidebar: Sidebar,
        default: {
          render (c) { return c('router-view') }
        }
      },
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'list',
          name: 'Accounts',
          component: Accounts,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: ':id',
          name: 'Account',
          component: Account,
          meta: {
            requiresAuth: true
          }
        }
      ]
    },
    {
      path: 'subscription',
      redirect: '/settings/subscription/billing',
      name: 'Subscription',
      components: {
        sidebar: Sidebar,
        default: {
          render (c) { return c('router-view') }
        }
      },
      children: [
        {
          path: 'billing',
          name: 'Billing',
          component: Billing,
          meta: {
            requiresAuth: true
          }
        }
      ]
    },
    {
      path: 'organizations',
      redirect: '/settings/organizations/list',
      name: 'Organizations',
      components: {
        sidebar: Sidebar,
        default: {
          render (c) { return c('router-view') }
        }
      },
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'list',
          name: 'All Organizations',
          component: Organizations,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: ':id',
          name: 'Organization',
          component: Organization,
          meta: {
            requiresAuth: true
          }
        }
      ]
    },
    {
      path: 'system',
      redirect: '/system/roles',
      components: {
        sidebar: Sidebar,
        default: {
          render (c) { return c('router-view') }
        }
      },
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'roles',
          name: 'Roles',
          component: Roles,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'application',
          name: 'Application Settings',
          components: {
            sidebar: Sidebar,
            default: ApplicationSettings
          },
          meta: {
            requiresAuth: true
          }
        },
      ]
    },
    {
      path: 'templates',
      redirect: '/templates/email',
      components: {
        sidebar: Sidebar,
        default: {
          render (c) { return c('router-view') }
        }
      },
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'email',
          name: 'Email Templates',
          component: EmailTemplates,
          meta: {
            requiresAuth: true
          }
        }
      ]
    },
    {
      path: 'integrations',
      redirect: '/integrations/mailgun',
      components: {
        sidebar: Sidebar,
        default: {
          render (c) { return c('router-view') }
        }
      },
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'mailgun',
          name: 'Mailgun',
          component: Mailgun,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'cardConnect',
          name: 'CardConnect',
          component: CardConnect,
          meta: {
            requiresAuth: true
          }
        }
      ]
    }
  ]
}]
