export default {
  name: 'policies',
  endpoints: [
    {
      method: 'GET',
      endpoint: '/info',
      hasParams: false,
      alias: 'info'
    },
    {
      method: 'GET',
      endpoint: '/types',
      hasParams: false,
      alias: 'types'
    },
    {
      method: 'PUT',
      endpoint: '/:id/calculatePremiums',
      hasParams: true,
      alias: 'calculatePremiums'
    },
    {
      method: 'PUT',
      endpoint: '/:id/calculateValues',
      hasParams: true,
      alias: 'calculateValues'
    },
    {
      method: 'GET',
      endpoint: '/client/:id',
      hasParams: true,
      alias: 'client'
    }
  ],
  services: [
    {
      name: 'existing',
      base: '/existing',
      defaultEndpoints: true
    }
  ]
}
